import React, { useMemo } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FeatureFlagContext } from '../context';
import { useInspectors } from '../hooks';
/**
 * Provider for feature flags.
 * NB: This provider must be rendered at the root of your react application.
 *
 * @example
 * const WrappedApp = () => (
 *   <React.StrictMode>
 *     <FeatureFlagProvider config={featureFlagConfig}>
 *       <App />
 *     </FeatureFlagProvider>
 *   </React.StrictMode>
 * );
 * createRoot(document.getElementById('approot')!).render(<WrappedApp />);
 */
export const FeatureFlagProvider = ({ children, config, onFlagUsed }) => {
    const { log, clientId, appId, appVersion } = config;
    const ldLogger = useMemo(() => {
        const childLogger = log.child({ module: 'launchDarkly' });
        return {
            error: childLogger.error.bind(childLogger),
            warn: childLogger.warn.bind(childLogger),
            // Info and debug are very spammy and we generally don't care that much so lets lower them
            info: childLogger.debug.bind(childLogger),
            debug: childLogger.trace.bind(childLogger),
        };
    }, [log]);
    const inspectors = useInspectors(onFlagUsed);
    const WrappedChildren = withLDProvider({
        reactOptions: { useCamelCaseFlagKeys: false },
        clientSideID: clientId,
        options: {
            inspectors,
            logger: ldLogger,
            streaming: true,
            application: { id: appId, version: appVersion },
        },
    })(() => children);
    return (React.createElement(FeatureFlagContext.Provider, { value: config },
        React.createElement(WrappedChildren, null)));
};
