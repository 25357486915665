import constants from 'app-constants';
import { FeatureFlags, FeatureFlagsConfig } from '@garner-health/lib-ui-feature-flags';
import { Logger } from '@garner-health/logging';
import logger from '~/logging';
import { analytics, UserTrackingProperties } from '~/analytics/segment';
import { defaults } from './flags';

const noop = () => undefined;

const noopLogger: Logger = {
  info: noop,
  warn: noop,
  error: noop,
  fatal: noop,
  trace: noop,
  debug: noop,
  silent: noop,
  level: 'silent',
  child: () => noopLogger,
  setBindings: noop,
  bindings: () => ({}),
};

// LaunchDarkly is super spammy and makes it difficult to find log messages - let's provide the option to disable logging
const customLogger: Logger = process.env.ENVIRONMENT === 'local' ? noopLogger : logger('lib-ui-feature-flags');

export const featureFlagsConfig: FeatureFlagsConfig = {
  log: customLogger,
  appId: constants.launchDarkly.appId,
  appVersion: GARNER_VERSION,
  clientId: constants.launchDarkly.clientId,
  onFlagsChange: (flags: FeatureFlags) => () => {
    const globalProperties = Object.entries(flags).reduce((acc, [k, v]) => {
      acc[`flag.${k}`] = v;
      return acc;
    }, {} as UserTrackingProperties);

    analytics.setGlobalProperties(globalProperties);
  },
  defaults,
};
