import { useCallback } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useConfig } from './use-config';
/**
 * Identify a user and set the context for flag values in the app.
 * This hook and returned callback function should be called at the top level of your application
 * to ensure that flag values are set appropriately according to the context of the application user.
 *
 * @see https://docs.launchdarkly.com/sdk/features/identify
 */
export function useIdentifyForFeatureFlags() {
    const ldClient = useLDClient();
    const { log } = useConfig();
    return useCallback((identity) => {
        if (!ldClient) {
            log.warn({ context: identity }, 'Feature flag client is null, cannot identify user');
            return;
        }
        log.debug({ context: identity }, 'Initializing user with LaunchDarkly');
        ldClient
            .identify({ kind: 'user', ...identity })
            .catch(err => log.error(err, 'Error initializing feature flag user context'));
    }, [ldClient, log]);
}
