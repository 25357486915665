import '~/content';

import React, { StrictMode } from 'react';
import constants from 'app-constants';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AuthConfig, AuthProvider } from '@garner-health/components-auth';
import { GarnerThemeProvider, LogProvider } from '@garner-health/components-common';
import { MapProvider } from '@garner-health/components-map';
import { ContentProvider, init as initContentManager } from '@garner-health/lib-ui-content-management';
import { FeatureFlagProvider } from '@garner-health/lib-ui-feature-flags';
import { AppStateProvider } from '~/contexts';
import { ErrorBoundary } from '~/error-boundary';
import { featureFlagsConfig } from '~/feature-flags';
import logger from '~/logging';
import { ErrorPage } from '~/pages';
import { useComponentConfig } from '~/theme';
import { analytics } from '~/analytics/segment';
import * as resourcePackages from '~/content/resource-packages';
import { router } from '~/router/router';
import { authTypeResolver } from './auth';

const log = logger(__filename);

analytics.initialize();

initContentManager({
  ...constants.contentful,
  log: logger(__filename, { component: 'content-manager' }),
  errorPage: <ErrorPage />,
  applicationStringAppId: 'ui-datapro',
});

// useComponentConfig() depends on the content provider being initialized, so it must be broken into it's own component
const ThemedApp = () => (
  <GarnerThemeProvider locale="en-US" componentConfig={useComponentConfig()}>
    <MapProvider config={{ accessToken: constants.mapbox.accessToken }}>
      <AppStateProvider>
        <RouterProvider router={router} />
      </AppStateProvider>
    </MapProvider>
  </GarnerThemeProvider>
);

const authConfig: AuthConfig = {
  loginRoute: '/sign-in',
  cognito: {
    ...constants.authConfig.cognito,
    oauth: {
      ...constants.authConfig.cognito.oauth,
      scope: ['openid', 'email'],
      responseType: 'code',
    },
  },
  log: logger(__filename, { component: 'components-auth' }),
  sso: {
    authTypeResolver,
  },
};

createRoot(document.getElementById('approot')!).render(
  <StrictMode>
    <FeatureFlagProvider config={featureFlagsConfig}>
      <AuthProvider config={authConfig}>
        <ErrorBoundary>
          <ContentProvider packages={Array.from(Object.keys(resourcePackages)) as (keyof typeof resourcePackages)[]}>
            <LogProvider log={log}>
              <ThemedApp />
            </LogProvider>
          </ContentProvider>
        </ErrorBoundary>
      </AuthProvider>
    </FeatureFlagProvider>
  </StrictMode>,
);
