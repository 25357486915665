import { useEffect } from 'react';
import { useFlags as useLaunchDarklyFlags } from 'launchdarkly-react-client-sdk';
import { useConfig } from './use-config';
export function useFlags() {
    const ldFlags = useLaunchDarklyFlags();
    const { log, defaults, onFlagsChange } = useConfig();
    useEffect(() => {
        onFlagsChange(ldFlags);
    }, [ldFlags, onFlagsChange]);
    return new Proxy(ldFlags, {
        get(target, prop) {
            const value = target[prop];
            if (value === undefined) {
                const defaultValue = prop in defaults ? defaults[prop] : undefined;
                log.debug({ strProp: prop, strDefault: defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.toString() }, 'Feature flag is undefined when being fetched, falling back to default');
                return defaultValue;
            }
            return value;
        },
    });
}
