import { useMemo } from 'react';
export function useInspectors(onFlagUsed) {
    return useMemo(() => {
        if (!onFlagUsed)
            return undefined;
        return [
            {
                type: 'flag-used',
                name: 'custom-on-flag-used',
                method: (key, detail) => onFlagUsed(key, detail.value),
            },
        ];
    }, [onFlagUsed]);
}
